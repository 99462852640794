import Head from "next/head";
import config from "utils/config";

const AISearchSEO: React.FC = () => {
  return (
    <Head>
      <title>{`Describe your requirement, Discover 'Your Human'.`}</title>
      <link rel="shortcut icon" href="/favicon.svg" type="image/x-icon" />
      <meta name="description" content="Google for People" />
      <meta
        property="og:title"
        content="Describe your requirement, Discover 'Your Human'."
      />
      <meta property="og:description" content="Google for People" />
      <meta
        property="og:image"
        content={`${config.BASE_URL}ai-search-meta-image.png`}
      />
      <meta
        property="twitter:image"
        content={`${config.BASE_URL}ai-search-meta-image.png`}
      />
      <meta
        property="og:image:secure_url"
        content={`${config.BASE_URL}ai-search-meta-image.png`}
      />
    </Head>
  );
};

export default AISearchSEO;
