import { Typography } from "antd";
import Container from "components/Common/Container";
import PublicLayout from "components/Layouts/Public/PublicLayout";
import dynamic from "next/dynamic";
import styles from "./AISearchPage.module.scss";
import AISearchSEO from "./AISearchSEO";

const AISearchNav = dynamic(() => import("./AISearchNav"), { ssr: false });
const AISearchContainer = dynamic(() => import("./AISearchContainer"), {
  ssr: false,
});

const { Paragraph } = Typography;

const AISearch = () => {
  return (
    <PublicLayout>
      <Container type="fluid">
        <AISearchSEO />
        <Paragraph className={styles.AISearchPage}>
          <AISearchNav />
          <AISearchContainer page="AISearch" />
        </Paragraph>
      </Container>
    </PublicLayout>
  );
};

export default AISearch;
