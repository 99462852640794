import AISearch from "components/PublicPages/AISearch/AISearch";
import { NextPage } from "next";

const HomepageComponent: NextPage = () => <AISearch />;

// export async function getServerSideProps() {
//   try {
//     const response: any = getCountry();
//     const jsonData: any = await response?.json();
//     return {
//       props: {
//         jsonData,
//       },
//     };
//   } catch (error) {
//     console.error("Error Fething Data", error);
//     return {
//       props: {
//         country: "IN",
//       },
//     };
//   }
// }

export default HomepageComponent;
